import React from "react";
import { cn } from "../../../../helper"

import * as Styles from "./Tile.module.scss";


const Tile = ({
    children,
    className,
    ...props
}) =>
    <div
        className={ cn(Styles.tile, className) }
        { ...props }
    >
        { children }
    </div>;

export default Tile;