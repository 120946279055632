import React from "react"

import LOGO from "../../../../assets/icons/colorized/karmalokal-logo-inverted.svg"
import { cn } from "../../../../helper"
import * as Styles from "./Logo.module.scss"


const Text = ({ className, ...props }) =>
	<h2
		className={ cn(
			Styles.companyName,
			className
		) }
		{ ...props }
	>
		Karma<span>lokal</span>
	</h2>

const Icon = ({ className, ...props }) =>
	<img
		src={ LOGO }
		className={ cn(
			className
		) }
		{ ...props }
		alt={ "Boomerang" }
	/>

const LogoTeaser = ({ logoClassName }) =>
	<div className={ Styles.logoTeaser }>
		<Icon className={ logoClassName }/>
		<Text/>
	</div>

export {
	LogoTeaser,
	Text,
	Icon
}