import React from "react";

import Base from "../components/layout/page/base/Base"
import Tile from "../components/ui/atom/tile/Tile"
import Link from "../components/ui/atom/link/Link"
import { LogoTeaser } from "../components/ui/atom/logo/Logo"
import Button from "../components/ui/atom/button/Button";
import ButtonGroup from "../components/ui/atom/button/button-group/ButtonGroup"
import Roadmap, { Entry } from "../components/ui/molecule/roadmap/Roadmap"

import * as Styles from "./prototype.module.scss";


const IndexPage = () =>
    <Base title={ "Willkommen" }>
        <section className={ Styles.section }>
            <LogoTeaser logoClassName={ Styles.logo }/>

            <hr/>

            <Roadmap>
                <Entry
                    label={ "Konzept" }
                    active
                >
                    <h4>Konzeption</h4>
                    <p>
                        Wir befinden uns derzeitig noch in der Konzeptionsphase. Für weitere Informationen und Anregungen können Sie liebend gern
                        Kontakt über <Link to={ "mailto:joseph@karmalokal.de" }>joseph@karmalokal.de</Link> mit uns aufnehmen. Wir freuen uns!
                    </p>
                </Entry>

                <Entry label={ "Finanzierung" }>
                    <h4>Finanzierung</h4>
                </Entry>
            </Roadmap>
        </section>
    </Base>;

export default IndexPage;
