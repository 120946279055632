import React from "react";

import * as Styles from "./Button.module.scss";


const Button = ({
    children,
    primary = true,
    renderAs = "button",
    ...props
}) => {
    const properties = {
        className:[
            Styles.button,
            primary ? Styles.primary : undefined
        ].join(" "),
        ...props
    }
    return React.createElement(
        renderAs,
        properties,
        children
    );
};

export default Button;