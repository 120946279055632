import React from "react"
import { Helmet } from "react-helmet"
import Footer from "../../footer/Footer"


const Base = ({
	title,
    children
}) =>
	<>
		<Helmet>
			<title>{ title } – Karmalokal · Die Spendenplattform für Geld-, Sach- und Zeitspenden</title>
		</Helmet>

		{ children }

		<Footer/>
	</>

export default Base