import React from "react";

import * as Styles from "./link.module.scss";


function startsWithHttp (link) {
	const regex = new RegExp(/https?:\/\//);
	return regex.test(link);
}

const Link = ({ children, to, ...props }) =>
	<a
		className={ Styles.anchor }
		target={ startsWithHttp(to) ? "_blank" : "_self" }
		href={ to }
		rel={ startsWithHttp(to) ? "noreferrer" : undefined }
		{ ...props }
	>
		{ children }
	</a>;

export default Link;