import React from "react"

import Tile from "../../atom/tile/Tile"
import * as Styles from "./Roadmap.module.scss"


const Roadmap = ({ children }) =>
	<section className={ Styles.roadmapWrapper }>
		<h3 className={ Styles.heading }>Roadmap</h3>
		<div className={ Styles.roadmap }>
			{ children }
		</div>
	</section>

const Entry = ({ active, children }) =>
	<article
		className={ Styles.entry }
		data-active={ active }
	>
		<div className={ Styles.timeline } />

		<Tile className={ Styles.content }>
			{ children }
		</Tile>
	</article>


export {
	Entry
}

export default Roadmap