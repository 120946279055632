import React from "react";

import * as Styles from "./ButtonGroup.module.scss";


// left is default position
// const LEFT = "left"
const CENTERED = "centered";
const RIGHT = "right";

const ButtonGroup = ({
    children,
    position = "left",
    renderAs = "button",
    ...props
}) => {
    const cn = [
        Styles.group,
    ]

    if (position === CENTERED) {
        cn.push(Styles.centered)
    } else if (position === RIGHT) {
        cn.push(Styles.right)
    }

    return (
        <div
            className={ cn.join(" ") }
            { ...props }
        >
            { children }
        </div>
    )
};

export default ButtonGroup;